import './uplift.scss';

function App() {
    return (
        <div className="page-container container-xl">
            <div className="row">
                <div className="col-sm-12">          
                    <div className="text-center">
                        <div className="page-prof-container">
                            <img className="page-prof rounded style-1" src="img/page-chris-prof.jpeg" alt="Page Pic"></img>
                        </div>
                        <h2>@chris</h2>
                        {/* <h2>@{window.location.pathname.substring(1)}</h2> */}
                        <div className="px-3 page-description">
                            <p>I enjoy design and how things are made.</p>
                        </div>
                        <div className="page-links pb-4">
                            <a href="https://dougliu.com" className='btn btn-primary btn-lg btn-block mb-3'>Website</a>
                            <a href="https://calendly.com/chrisuniversity" className='btn btn-outline btn-primary btn-lg btn-block'>Book me</a>
                        </div>
                        {/* <ul className="list-group mb-4">
                            <button className="list-group-item">Instagram</button>
                            <button className="list-group-item">Github</button>
                            <button className="list-group-item btn">LinkedIn</button>
                            <button className="list-group-item btn btn-primary">Book me</button>
                        </ul> */}
                        <div className="page-icons mb-4">
                            <a href="https://instagram.com/chrisuniversity" className="button btn m-2"><img src="icons/instagram.svg" width="30" height="30" alt=""></img></a>
                            <a href="https://facebook.com/chrisuniversity" className="button btn m-2"><img src="icons/facebook-square.svg" width="30" height="30" alt=""></img></a>
                            <a href="https://www.linkedin.com/in/chrisuniversity/" className="button btn m-2"><img src="icons/linkedin.svg" width="30" height="30" alt=""></img></a>
                            <a href="https://github.com/chris920" className="button btn m-2"><img src="icons/github.svg" width="30" height="30" alt=""></img></a>
                        </div>
                    </div>
                </div>
            </div>                  
        </div>
    );
};

export default App;




