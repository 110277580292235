import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from "firebase";
var firebaseConfig = {
  apiKey: "AIzaSyB2bhBS9xTgmi52PUJEYdKuMhm318ptqBY",
  authDomain: "uplift-duo.firebaseapp.com",
  databaseURL: "https://uplift-duo-default-rtdb.firebaseio.com",
  projectId: "uplift-duo",
  storageBucket: "uplift-duo.appspot.com",
  messagingSenderId: "548082577974",
  appId: "1:548082577974:web:27acf848d6696e91c49e97",
  measurementId: "G-QWEZHST16Y"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

const firebaseApp = firebase.apps[0];
console.log(firebaseApp);
reportWebVitals(console.log);
